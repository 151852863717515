import styled from 'styled-components';

import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

import { breakpoint } from '../../../theme';
import Button from '../../atoms/button';

export const StyledWrapper = styled.div`
  &:hover {
    .card-with-icon__button {
      span {
        display: block;
        animation: move-text-cart 0.2s linear forwards;
      }

      svg {
        animation: move-svg 0.2s linear forwards;
      }
    }

    .card-with-icon__button span {
      ${breakpoint.md} {
        display: block;
      }
    }
  }

  @keyframes move-svg {
    from {
      opacity: 0;
      transform: translateX(-437%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes move-text-cart {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export const StyledImage = styled.div`
  margin-bottom: ${({ theme }) => theme.gap.sm};

  height: ${({ theme }) => theme.gap.xxl};
  width: ${({ theme }) => theme.gap.xxl};
`;

export const StyledRole = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};
  margin-bottom: ${({ theme }) => theme.gap.sm};
`;

export const StyledIcon = styled(Icon)<{ color: string; contactList?: boolean }>`
  color: ${({ color }) => color};
  border: ${({ contactList }) => (contactList ? '1px solid #BEC0C5' : 'none')};
  padding: ${({ contactList }) => (contactList ? '7px' : 'none')};
  margin-right: ${({ contactList, theme }) => (contactList ? theme.gap.sm : '0')};
  height: ${({ contactList, theme }) => (contactList ? theme.gap.md : 'auto')};
  width: ${({ contactList, theme }) => (contactList ? theme.gap.md : 'auto')};

  &:hover {
    border: ${({ contactList }) => (contactList ? '1px solid #000000' : 'none')};
  }
`;

export const StyledButton = styled(Button)`
  height: 20px;

  span {
    ${breakpoint.md} {
      display: none;
    }
  }
`;
