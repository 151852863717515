import React from 'react';
import { CustomThemeColorType } from 'theme/styled.d';
import { DefaultType } from 'types/default';

import PersonCard, { PersonsCardType } from 'components/molecules/person-card';
import Typography from 'components/atoms/typography';

import {
  StyledCardWrapper,
  StyledContent,
  StyledTitle,
  StyledWrapper,
} from './board-section.styles';

export type BoardSectionType = {
  sectionHeading: string;
  sectionList: PersonsCardType[];
  sectionText?: string;
  iconColor?: CustomThemeColorType;
} & DefaultType;

const BoardSection = ({
  sectionHeading,
  sectionText,
  sectionList,
  iconColor,
  margin,
  mobilePadding,
  mobileMargin,
  padding,
  bgColor,
}: BoardSectionType) => (
  <StyledWrapper bgColor={bgColor}>
    <StyledContent
      margin={margin}
      mobilePadding={mobilePadding}
      mobileMargin={mobileMargin}
      padding={padding}
    >
      <StyledTitle color="gray100" variant="title2" component="h2" html={sectionHeading} />
      {sectionText ? <Typography color="gray100" variant="body1" html={sectionText} /> : null}
      {sectionList ? (
        <StyledCardWrapper>
          <>
            {sectionList.map((item: PersonsCardType) => (
              <PersonCard iconColor={iconColor} {...item} />
            ))}
          </>
        </StyledCardWrapper>
      ) : null}
    </StyledContent>
  </StyledWrapper>
);

export default BoardSection;
