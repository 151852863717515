import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import BoardSection, { BoardSectionType } from 'components/organisms/board-section';
import HeadTitleDescription from 'components/organisms/head-title-description';
import MoreInformationSection, {
  MoreInformationSectionType,
} from 'components/organisms/more-information-section';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  aboutBoardPage: {
    template: {
      aboutBoardData: {
        bannerSimple: {
          heading: string;
          text: string;
        };
        administrationList: BoardSectionType[];
        moreInformationSection: MoreInformationSectionType;
      };
    };
  };
};

const AboutBoardPage = ({
  pageContext,
  data: {
    aboutBoardPage: {
      template: {
        aboutBoardData: {
          moreInformationSection,
          bannerSimple: { heading, text },
          administrationList,
        },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescription
      titleTop={heading}
      subtitleTop={text}
      containerWidth="full"
      padding="0"
      showDots
      isWhite
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescription>
    {administrationList
      ? administrationList.map((item, i) => {
          const isLastElement = administrationList.length === i + 1;

          return (
            <BoardSection
              sectionHeading={item.sectionHeading}
              sectionList={item.sectionList}
              iconColor="primary50"
              padding={
                isLastElement ? `${theme.gap.lg} 0 ${theme.gap.lg} 0` : `${theme.gap.lg} 0 0 0`
              }
              key={item.sectionHeading}
            />
          );
        })
      : null}
    <MoreInformationSection {...moreInformationSection} />
  </Layout>
);

export const query = graphql`
  query AboutBoardPage($id: String) {
    aboutBoardPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutBoard {
          templateName
          aboutBoardData {
            moreInformationSection {
              heading
              cardList {
                text
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      id
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
                icon {
                  name
                }
              }
            }
            bannerSimple {
              heading
              text
            }
            administrationList {
              sectionHeading
              sectionList {
                firstName
                position
                surname
                image {
                  ...WpImageFragment
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                  icon {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutBoardPage;
