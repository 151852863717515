import React from 'react';
import { navigate } from 'gatsby';
import { theme } from 'theme';
import { CustomThemeColorType } from 'theme/styled.d';

import { IconNameType } from 'components/atoms/icon';
import Link, { LinkUrlType } from 'components/atoms/link';
import Typography from 'components/atoms/typography';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import {
  StyledButton,
  StyledIcon,
  StyledImage,
  StyledRole,
  StyledWrapper,
} from './person-card.styles';

export type PersonsCardType = {
  position: string;
  firstName?: string;
  surname?: string;
  fullName?: string;
  variant?: string;
  image: WpImageType;
  iconColor?: CustomThemeColorType;
  linkButton?: { icon: { name: IconNameType }; link: LinkUrlType };
  contactList?: {
    type: IconNameType;
    link: LinkUrlType;
  }[];
};

const PersonCard = ({
  firstName,
  surname,
  fullName,
  position,
  image,
  iconColor,
  linkButton,
  contactList,
}: PersonsCardType) => {
  const getIconColor = iconColor ? theme.colors[iconColor] : theme.colors.gray60;

  const isLink = Boolean(linkButton?.link);

  const renderName = () => {
    const nameContent = fullName ? (
      <Typography color="gray100" variant="title3" component="h3" html={fullName} />
    ) : (
      <>
        <Typography color="gray100" variant="title3" component="h3" html={firstName} />
        <Typography color="gray100" variant="title3" component="h3" html={surname} />
      </>
    );

    return isLink && linkButton?.link ? (
      <Link {...linkButton.link} ariaLabel={fullName || `${firstName} ${surname}`}>
        {nameContent}
      </Link>
    ) : (
      nameContent
    );
  };

  const getLinkUri = (link?: LinkUrlType) => {
    if (!link) return '';
    if (link.internal) {
      return typeof link.internal === 'string' ? link.internal : link.internal.uri;
    }
    return link.external?.url || '';
  };
  const handleWrapperClick = () => {
    const uri = getLinkUri(linkButton?.link);
    if (uri) {
      navigate(uri);
    }
  };
  return (
    <StyledWrapper data-link={getLinkUri(linkButton?.link)} onClick={handleWrapperClick}>
      <StyledImage>
        <WpImage {...image} />
      </StyledImage>
      {renderName()}
      <StyledRole color="gray60" variant="title5" html={position} />
      {linkButton?.link ? (
        <StyledButton
          {...linkButton}
          icon={{ name: 'arrow-right' }}
          width="max-content"
          variant="reversed-underline"
          className="card-with-icon__button"
        />
      ) : null}
      {contactList?.map((item) => (
        <Link {...item.link} ariaLabel={item.type} key={item.type}>
          <StyledIcon name={item.type} color={getIconColor} contactList />
        </Link>
      ))}
    </StyledWrapper>
  );
};

export default PersonCard;
