import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.grayscaleGray0};
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.sm};
`;

export const StyledContent = styled.div<DefaultType>`
  margin: ${({ mobileMargin }) => mobileMargin || '0 auto'};
  padding: ${({ theme, mobilePadding }) => mobilePadding || theme.gap.sm};
  max-width: ${({ theme }) => theme.wrapper};

  ${breakpoint.md} {
    margin: ${({ margin }) => margin || '0 auto'};
    padding: ${({ theme, padding }) => padding || `${theme.gap.lg} 0 0 0`};
  }
`;

export const StyledCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 156px);
  grid-gap: ${({ theme }) => theme.gap.sm};
  margin-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
    grid-template-columns: repeat(auto-fit, 270px);
    grid-gap: ${({ theme }) => theme.gap.md};
  }

  ${breakpoint.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
